import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComunicadosService } from '../../../../service/comunicados/comunicados.service';
import { Subscription } from 'rxjs/Subscription';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../utils/swalObject';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { ChangeDetectorRef } from '@angular/core';
import { OrganogramaService } from 'src/app/service/organograma/organograma.service';

declare var $;

@Component({
  selector: 'app-incluir-comunicado',
  templateUrl: './incluir-comunicados.component.html',
  styleUrls: ['./incluir-comunicados.component.scss']
})
export class IncluirTiposComunicadosComponent implements OnInit, AfterViewInit {
  @ViewChild('myTree', { static: true }) myTree: jqxTreeComponent;

  opcoesPrazo: any;
  opcaoPrazo;
  prazo: number = null;
  cor: number = null;
  inclusaoComunicadoForm: FormGroup;
  form: FormGroup;
  sistemasDisponiveis = [];
  sistemasAutorizados = [];
  sistemas = [];
  cores: Array<any> = [];
  edicao: boolean = false;
  visualizacao: boolean = false;
  ativo: boolean = false;
  numDias: boolean = false;
  prazoCiencia: number = null;
  validade: number = null;
  numAlertaCienciaTacita: number = null
  numDiasRealocarCiencia: boolean = false;
  popupLeituraImpositiva: boolean = false;
  contribuinteInicia: boolean = false;
  contribuinteResponde: boolean = false;
  notificaViaSms: boolean = false;
  usoEmail: boolean = false;
  resultViaSms: boolean;
  auditorInicia: boolean = false;
  informarRemetente: boolean = false; //
  nomearAdvogado: boolean = false;
  resultViaEmail: boolean;
  enviaAlertaApp: boolean = false;
  reenviaMensagemAposPrazo: boolean = false;
  exigeAssinaturaDigital: boolean = false;
  utilizadoSistemasCorporativos: boolean = false;
  respostaBloqueada: boolean = false;
  informacoesExibidas: boolean = false;
  mensagem: boolean = false;
  dataEnvio: boolean = false;
  numeroProtocolo: boolean = false;
  esconderBotao: boolean = false;
  textoPersonalizado: string;
  corSelecionada: object = {
    codigo: -1
  };
  utilizar: string = 'não';
  codigoTipoPrazo: number = null;
  idComunicado: number = null;
  routeSub: Subscription;
  title: string = 'Incluir Tipo de Comunicado';
  data: any = [];
  dataOrganograma: any = [];
  source = {
    datatype: 'json',
    datafields: [
      { name: 'contagem', type: 'number'},
      { name: 'id' },
      { name: 'parentId' },
      { name: 'text' }
    ],
    id: 'id',
    localdata: null
  };
  dataAdapter = new jqx.dataAdapter(this.source);
  records: any = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: 'text', map: 'label' }]);
  checkedItem: any = [];
  organograma: any = [];
  permiteAnexo: boolean = false;
  permiteAlterarComunicado: boolean = false;
  caracteres: number = 0;
  config: any;
  mensagemPadrao: string;
  comunicadoPadronizado: boolean = false;
  assunto: string = "";
  loading: boolean = false;
  listaOrganograma: any[] = [];
  dataOrg: any[] = [];
  expandedItems = new Set<number>();

  constructor(
    private fb: FormBuilder,
    private comunicadosService: ComunicadosService,
    private route: ActivatedRoute,
    private router: Router,
    private tipoComunicadosService: ComunicadosService,
    private getComunicadosEmail: ComunicadosService,
    private getComunicadosSms: ComunicadosService,
    private organogramaService: OrganogramaService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.routeSub = this.route.params.subscribe(params => {
      if (params['id']) {
        this.idComunicado = params['id'];
        this.edicao = true;
        this.title = 'Editar Tipo de Comunicado';
      }

      this.form = this.fb.group({
        organogramaChecked: [false],
        nivelChecked: [false],
        subnivelChecked: [false]
      });
    });
  }

  ngOnInit() {
    this.loading = true;
    this.criarFormulario();
    this.getData();
    this.configCaixaTexto();
    this.getOrganograma();
    this.sistemasDisponiveis = this.sistemas;
    this.sistemasDisponiveis.forEach(elem => (elem['allow'] = false));
    this.tipoComunicadosService.obterCores().subscribe(data => {
      this.cores = [
        {
          codigo: -1,
          cor: null,
          descricao: 'Selecione'
        }
      ];
      this.cores = this.cores.concat(data.resultado);
    });

    this.comunicadosService.getServidorComunicacao().subscribe(data => {
      this.resultViaSms = data.resultado.endpointSms;
      this.resultViaEmail = data.resultado.enderecoServidorEmail;
      console.log(data.resultado);
    });
  }

  ngAfterViewInit(): void {
    //this.getOrganograma();
  }
  // verifyCheckEmail() {
  //   if (this.resultViaEmail != true) {
  //     Swal.fire({
  //       title: 'Erro!',
  //       text:
  //         'Não é possível usar este recurso por não estar habilitado no ambiente',
  //       type: 'error',
  //       showCancelButton: false,
  //       confirmButtonText: 'OK!',
  //     }).then(result => {
  //       this.usoEmail = this.resultViaEmail;
  //     });
  //   }
  // }

  // verifyCheckSms() {
  //   if (!this.resultViaSms) {
  //     Swal.fire({
  //       title: 'Erro!',
  //       text:
  //         'Não é possível usar este recurso por não estar habilitado no ambiente',
  //       type: 'error',
  //       showCancelButton: false,
  //       confirmButtonText: 'OK!',
  //     }).then(result => {
  //       this.notificaViaSms = this.resultViaSms;
  //     });
  //   }
  // }

  updateValue(val) {
    this.utilizadoSistemasCorporativos = val;
  }

  criarFormulario() {
    this.inclusaoComunicadoForm = this.fb.group({
      cor: ['-1', [Validators.required, Validators.min(0)]],
      descricao: ['', Validators.required],
      prazo: ['-1', [Validators.required, Validators.min(0)]],
      ativo: ['', Validators.required],
      objetivo: ['', Validators.required],
      popupLeituraImpositiva: ['', Validators.required],
      contribuinteInicia: ['', Validators.required],
      contribuinteResponde: ['', Validators.required],
      notificaViaSms: ['', Validators.required],
      usoEmail: ['', Validators.required],
      enviaAlertaApp: ['', Validators.required],
      reenviaMensagemAposPrazo: ['', Validators.required],
      exigeAssinaturaDigital: ['', Validators.required],
      auditorInicia: ['', Validators.required],
      informarRemetente: ['', Validators.required], //
      nomearAdvogado: ['', Validators.required],
      respostaBloqueada: ['', Validators.required],
      prazoCiencia: ['', [Validators.min(1)]],
      numDias: ['', Validators.required],
      numDiasRealocarCiencia: ['', Validators.required],
      validade: [''],
      numAlertaCienciaTacita: ['', [Validators.min(1)]],
      mensagemPadrao: [''],
      permiteAnexo: [''],
      permiteAlterarComunicado: [''],
      assunto: [''],
      comunicadoPadronizado: ['']

      /*assunto: [''],
      mensagem:[''],
      dataEnvio:[''],
      numeroProtocolo:[''],
      textoPersonalizado:[''],*/

    });
  }

  getData() {
    this.comunicadosService.getData().subscribe(data => {
      this.opcoesPrazo = [
        {
          codigo: -1,
          descricao: 'Selecione',
          dataCriacao: '',
          ativo: true
        }
      ];

      this.opcoesPrazo = this.opcoesPrazo.concat(data[0].resultado);

      this.sistemas = data[1].resultado;
      this.sistemasDisponiveis = this.sistemas;

      if (this.idComunicado) {
        this.getComunicado(this.idComunicado);
      }
    });
  }

  onChange(event) {
    if (event === 'sim' && this.sistemasDisponiveis.length === 0) {
      Swal.fire(
        getSwal(
          'error',
          'Ops!',
          'Não existe nenhuma aplicação disponível para ser associada.',
          'ok',
          null
        )
      );
    }
  }

  getComunicado(id) {
    this.comunicadosService
      .obterComunicado(id)
      .subscribe(res =>{
        this.carregarComunicado(res)
        if(res.resultado.descricao == 'Domicílio Notifica') {
          this.esconderBotao = true;
        }
      });
  }

  carregarComunicado(res) {
    const comunicado = res.resultado;
    this.ativo = comunicado.ativo;

    console.log("ATIVO:" + this.ativo);

    if (comunicado.emUso) {
      this.edicao = false;
      this.visualizacao = true;
      this.title = 'Visualizar Tipo de Comunicado';
      this.inclusaoComunicadoForm.disable();
    }
    // this.numDias = comunicado.numDias;
    //this.prazoCiencia = comunicado.prazoCiencia;
    if (comunicado.prazoCiencia > 0) {
      this.numDias = true;
      this.prazoCiencia = comunicado.prazoCiencia;
    } else {
      this.prazoCiencia = null;
    }

    if (comunicado.numAlertaCienciaTacita > 0) {
      this.numDiasRealocarCiencia = true;
      this.numAlertaCienciaTacita = comunicado.numAlertaCienciaTacita;
    } else {
      this.numAlertaCienciaTacita = null;
    }

    if (comunicado.comunicadoPadrao !== null) {
      this.assunto = comunicado.comunicadoPadrao.assunto;
      this.mensagemPadrao = comunicado.comunicadoPadrao.mensagemPadrao;
    }

    this.popupLeituraImpositiva = comunicado.popupLeituraImpositiva;
    this.contribuinteInicia = comunicado.contribuinteInicia;
    this.contribuinteResponde = comunicado.contribuinteResponde;
    this.notificaViaSms = comunicado.notificaViaSms;
    this.usoEmail = comunicado.usoEmail;
    this.enviaAlertaApp = comunicado.enviaAlertaApp;
    this.reenviaMensagemAposPrazo = comunicado.reenviaMensagemAposPrazo;
    this.validade = comunicado.validade;
    this.exigeAssinaturaDigital = comunicado.exigeAssinaturaDigital;
    this.utilizadoSistemasCorporativos =
      comunicado.utilizadoSistemasCorporativos;
    this.opcaoPrazo = comunicado.tipoPrazo;
    this.corSelecionada = comunicado.cor;
    this.auditorInicia = comunicado.auditorInicia;
    this.informarRemetente = comunicado.informarRemetente; //
    this.nomearAdvogado = comunicado.nomearAdvogado;
    this.respostaBloqueada = comunicado.respostaBloqueada;
    this.permiteAnexo = comunicado.permiteAnexo;
    this.permiteAlterarComunicado = comunicado.permiteAlterarComunicado;
    this.comunicadoPadronizado = comunicado.mensagemPadrao;
    //this.organograma = comunicado.organograma;

    /*this.assunto = comunicado.assunto;
    this.mensagem = comunicado.mensagem;
    this.dataEnvio = comunicado.dataEnvio;
    this.numeroProtocolo = comunicado.numeroProtocolo;
    this.textoPersonalizado = comunicado.textoPersonalizado;*/

    if (comunicado.aplicacoes) {
      this.utilizar = 'sim';
      this.sistemasAutorizados = comunicado.aplicacoes;
      this.sistemasAutorizados.map(elem => {
        this.sistemasDisponiveis.map(item => {
          if (item.codigo === elem.codigo) {
            item['allow'] = true;
          }
        });
      });
    }
    this.inclusaoComunicadoForm.patchValue({
      descricao: comunicado.descricao,
      // validade: comunicado.validade,
      objetivo: comunicado.objetivo,
      cor: comunicado.cor.codigo,
      prazo: comunicado.tipoPrazo.codigo,
      // ['-1', [Validators.required, Validators.min(0)]],
      ativo: comunicado.ativo,
      //numDias: comunicado.numDias,
      // prazoCiencia: comunicado.prazoCiencia,
      validade: comunicado.validade,
      popupLeituraImpositiva: comunicado.popupLeituraImpositiva,
      contribuinteInicia: comunicado.contribuinteInicia,
      contribuinteResponde: comunicado.contribuinteResponde,
      notificaViaSms: comunicado.notificaViaSms,
      usoEmail: comunicado.usoEmail,
      enviaAlertaApp: comunicado.enviaAlertaApp,
      reenviaMensagemAposPrazo: comunicado.reenviaMensagemAposPrazo,
      exigeAssinaturaDigital: comunicado.exigeAssinaturaDigital,
      auditorInicia: comunicado.auditorInicia,
      informarRemetente: comunicado.informarRemetente, //
      nomearAdvogado: comunicado.nomearAdvogado,
      respostaBloqueada: comunicado.respostaBloqueada,
      permiteAnexo: comunicado.permiteAnexo,
      permiteAlterarComunicado: comunicado.permiteAlterarComunicado,
      comunicadoPadronizado: comunicado.mensagemPadrao

      /*assunto: comunicado.assunto,
      mensagem: comunicado.mensagem,
      dataEnvio: comunicado.dataEnvio,
      numeroProtocolo: comunicado.numeroProtocolo,
      textoPersonalizado: comunicado.textoPersonalizado*/
    });

    this.prazo = this.opcoesPrazo.map(e => e.codigo).indexOf(comunicado.tipoPrazo.codigo);

    this.loading = false;
  }

  selectPrazo(codigo) {
    if (typeof codigo === 'string') {
      codigo = parseInt(codigo);
    }
    this.opcaoPrazo = this.opcoesPrazo.filter(
      elem => elem.codigo === codigo
    )[0];
  }

  selecionaCor(codigoCor) {
    codigoCor = parseInt(codigoCor);
    this.corSelecionada = this.cores.filter(
      elem => elem.codigo === codigoCor
    )[0];
  }

  autorizaSistema(sistema) {
    sistema['allow'] = !sistema['allow'];
    if (sistema['allow']) {
      this.sistemasAutorizados.push(sistema);
    }

    this.sistemasAutorizados = this.sistemasAutorizados.filter(sistema => {
      if (sistema && sistema['allow']) {
        return sistema;
      }
    });
  }

  desautorizaSistema(sistema) {
    sistema['allow'] = false;
    this.sistemasAutorizados = this.sistemasAutorizados.filter(
      elem => elem.codigo !== sistema.codigo
    );
    this.sistemasDisponiveis.push(sistema);
  }
  limpaDias(evt: any) {
    if (this.inclusaoComunicadoForm.controls.numDias.value === true) {
      this.inclusaoComunicadoForm.controls['prazoCiencia'].clearValidators();
      this.inclusaoComunicadoForm.controls['prazoCiencia'].setValidators([
        Validators.required,
        Validators.min(1)
      ]);
      this.inclusaoComunicadoForm.controls[
        'prazoCiencia'
      ].updateValueAndValidity();
      // console.log('fieldprazoCiencia', this.inclusaoComunicadoForm.controls.prazoCiencia)
    } else {
      this.inclusaoComunicadoForm.controls['prazoCiencia'].clearValidators();
      this.inclusaoComunicadoForm.controls.prazoCiencia.setValidators([
        Validators.min(1)
      ]);
      this.inclusaoComunicadoForm.controls[
        'prazoCiencia'
      ].updateValueAndValidity();
      // console.log('fieldprazoCiencia', this.inclusaoComunicadoForm.controls.prazoCiencia)
    }
    // console.log('cientia: ',this.numDias);
    if (this.numDias == false) {
      this.prazoCiencia = null;
    }
  }

  limpaDiasNumAlerta(evt: any) {
    if (this.inclusaoComunicadoForm.controls.numDiasRealocarCiencia.value === true) {
      this.inclusaoComunicadoForm.controls['numAlertaCienciaTacita'].clearValidators();
      this.inclusaoComunicadoForm.controls['numAlertaCienciaTacita'].setValidators([
        Validators.required,
        Validators.min(1)
      ]);
      this.inclusaoComunicadoForm.controls[
        'numAlertaCienciaTacita'
      ].updateValueAndValidity();
      // console.log('fieldprazoCiencia', this.inclusaoComunicadoForm.controls.prazoCiencia)
    } else {
      this.inclusaoComunicadoForm.controls['numAlertaCienciaTacita'].clearValidators();
      this.inclusaoComunicadoForm.controls.numAlertaCienciaTacita.setValidators([
        Validators.min(1)
      ]);
      this.inclusaoComunicadoForm.controls[
        'numAlertaCienciaTacita'
      ].updateValueAndValidity();
      // console.log('fieldprazoCiencia', this.inclusaoComunicadoForm.controls.prazoCiencia)
    }
    // console.log('cientia: ',this.numDias);
    if (this.numDiasRealocarCiencia == false) {
      this.numAlertaCienciaTacita = null;
    }
  }

  checkedDia(evt: any) {
    // console.log('digitandoooooo: ',evt);
    if (evt.key == 0) {
      if (this.prazoCiencia == 0) {
        this.numDias = false;
        this.respostaBloqueada = false;
        this.inclusaoComunicadoForm.controls['prazoCiencia'].clearValidators();
        this.inclusaoComunicadoForm.controls[
          'prazoCiencia'
        ].updateValueAndValidity();
      } else if (this.prazoCiencia < 0) {
        this.numDias = false;
        this.respostaBloqueada = false;
        this.inclusaoComunicadoForm.controls['prazoCiencia'].clearValidators();
        this.inclusaoComunicadoForm.controls[
          'prazoCiencia'
        ].updateValueAndValidity();
      }
    } else if (!Number(this.prazoCiencia)) {
      this.numDias = false;
      this.respostaBloqueada = false;
      this.inclusaoComunicadoForm.controls['prazoCiencia'].clearValidators();
      this.inclusaoComunicadoForm.controls[
        'prazoCiencia'
      ].updateValueAndValidity();
    } else {
      this.numDias = true;
    }
  }

  limpaPrazo(evt: any) {
    if (
      this.inclusaoComunicadoForm.controls.reenviaMensagemAposPrazo.value ===
      true
    ) {
      this.inclusaoComunicadoForm.controls['validade'].clearValidators();
      this.inclusaoComunicadoForm.controls['validade'].setValidators([
        Validators.required,
        Validators.min(1)
      ]);
      this.inclusaoComunicadoForm.controls['validade'].updateValueAndValidity();
      // console.log('fieldprazoCiencia', this.inclusaoComunicadoForm.controls.prazoCiencia)
    } else {
      this.inclusaoComunicadoForm.controls['validade'].clearValidators();
      this.inclusaoComunicadoForm.controls.validade.setValidators([
        Validators.min(1)
      ]);
      this.inclusaoComunicadoForm.controls['validade'].updateValueAndValidity();
      // console.log('fieldprazoCiencia', this.inclusaoComunicadoForm.controls.prazoCiencia)
    }
    if (this.reenviaMensagemAposPrazo == false) {
      this.validade = null;
    }
  }

  checkedPrazo(evt: any) {
    // console.log('digitandoooooo: ',this.validade);

    if (evt.key == 0) {
      if (this.validade == 0) {
        this.reenviaMensagemAposPrazo = false;
        this.inclusaoComunicadoForm.controls['validade'].clearValidators();
        this.inclusaoComunicadoForm.controls[
          'validade'
        ].updateValueAndValidity();
      } else if (this.validade < 0) {
        this.reenviaMensagemAposPrazo = false;
        this.inclusaoComunicadoForm.controls['validade'].clearValidators();
        this.inclusaoComunicadoForm.controls[
          'validade'
        ].updateValueAndValidity();
      }
    } else if (!Number(this.validade)) {
      this.reenviaMensagemAposPrazo = false;
      this.inclusaoComunicadoForm.controls['validade'].clearValidators();
      this.inclusaoComunicadoForm.controls['validade'].updateValueAndValidity();
    } else {
      this.reenviaMensagemAposPrazo = true;
    }
  }

  limpaBloqueioResposta() {

    if (this.numDias == true) {
      this.respostaBloqueada = false;
    }
  }

  //
  limpaContribuinteResponde() {

    if (this.contribuinteResponde == true) {
      this.informarRemetente = false;
    }
  }

  camposPadronizacaoObrigatorios() {
    if (this.comunicadoPadronizado == true) {
      this.inclusaoComunicadoForm.controls['mensagemPadrao'].setValidators([
        Validators.required, Validators.minLength(20)]);
      this.inclusaoComunicadoForm.controls['mensagemPadrao'].updateValueAndValidity();

      this.inclusaoComunicadoForm.controls['contribuinteInicia'].setValue(false);

    } else {
      this.inclusaoComunicadoForm.controls['mensagemPadrao'].clearValidators();
      this.inclusaoComunicadoForm.controls['mensagemPadrao'].updateValueAndValidity();
    }
  }

  ajudaBloqueioResposta() { //ajudaBloqueioResposta
    Swal.fire(
      getSwal(
        'info',
        null,
        'O bloqueio exige que se defina um prazo para ciência tácita',
        'ok',
        null
      ))
  }

  //
  ajudaContribuinteResponde() { //ajudaContribuinteResponde
    Swal.fire(
      getSwal(
        'info',
        null,
        'Ativar esta opção implica que a será enviado um e-mail de notificação ao remetente que sua mensagem foi respondida pelo destinatário.',
        'ok',
        null
      ))
  }

  ajudaReenviarComunicao() {
    Swal.fire(
      getSwal(
        'info',
        null,
        'Esta opção informa se a mensagem deverá ser enviada após a extinção do prazo de ciência, reabrindo a contagem, se houver.',
        'ok',
        null
      ))
  }

  ajudaConteudoPadronizado() {
    Swal.fire(
      getSwal(
        'info',
        null,
        'Recurso que permite definir um texto para o assunto e conteúdo da mensagem, padronizando, não sendo possível alterar o conteúdo durante o envio, mas possibilita adicionar anexos no momento do envio, se assim configurado.',
        'ok',
        null
      )
    )
  }

  ajudaAssuntoPadronizado() {
    Swal.fire(
      getSwal(
        'info',
        null,
        'Assunto da mensagem',
        'ok',
        null
      )
    )
  }

  /*especificacaoPopup(){
    if(this.popupLeituraImpositiva == false){
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].setValidators([Validators.required]);
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].updateValueAndValidity();

      this.inclusaoComunicadoForm.controls['assunto'].setValidators([Validators.required]);
      this.inclusaoComunicadoForm.controls['assunto'].updateValueAndValidity();
    }else{
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].clearValidators();
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].updateValueAndValidity();

      this.inclusaoComunicadoForm.controls['assunto'].clearValidators();
      this.inclusaoComunicadoForm.controls['assunto'].updateValueAndValidity();
    }
  }

  removeValidacaoTexto(){
    if(this.assunto == false){
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].clearValidators();
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].updateValueAndValidity();
    }else{
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].setValidators([Validators.required]);
      this.inclusaoComunicadoForm.controls['textoPersonalizado'].updateValueAndValidity();
    }
    
  }
  removeValidacaoAssunto(){
    this.inclusaoComunicadoForm.controls['assunto'].clearValidators();
    this.inclusaoComunicadoForm.controls['assunto'].updateValueAndValidity();
  }*/

  autorizaTodos(evt: any) {
    this.sistemasAutorizados = this.sistemas;
    this.sistemasDisponiveis.forEach(elem => (elem['allow'] = true));
  }

  desautorizaTodos(evt: any) {
    this.sistemasAutorizados = [];
    this.sistemasDisponiveis = this.sistemas;
    this.sistemasDisponiveis.forEach(elem => (elem['allow'] = false));
  }

  criaComunicado() {
    this.loading = true;
    const tipoComunicado = this.inclusaoComunicadoForm.getRawValue()
    tipoComunicado['comunicadoPadrao'] = {}
    if (this.comunicadoPadronizado == true) {
      tipoComunicado['comunicadoPadrao'] = {
        assunto: this.assunto,
        ativo: this.comunicadoPadronizado,
        dataCriacao: new Date(),
        mensagemPadrao: this.mensagemPadrao
      }
    } else {
      tipoComunicado['comunicadoPadrao'] = null
      tipoComunicado['permiteAnexo'] = this.permiteAnexo;
      tipoComunicado['permiteAlterarComunicado'] = this.permiteAlterarComunicado
    }
    tipoComunicado['mensagemPadrao'] = this.comunicadoPadronizado;
    tipoComunicado['aplicacoes'] = this.sistemasAutorizados;
    tipoComunicado['tipoPrazo'] = this.opcaoPrazo;
    tipoComunicado['cor'] = this.corSelecionada;

    tipoComunicado['organograma'] = [];
    if (this.checkedItem.lenght !== 0) {
      let childId = 2;
      this.checkedItem.forEach((value, index) => {
    
        const isOrgPai = value.isPai == true;
        let parentOrganograma = null;
    
        if (isOrgPai) {
          parentOrganograma = {
            ativo: true,
            checked: true,
            dataCriacao: value.dataCriacao,
            id: 1,
            parentId: 0,
            seqOrganograma: 0,
            sequencial: value.id,
            text: value.dscOrganograma
          };
    
          tipoComunicado['organograma'].push(parentOrganograma);
        }
    
        if (!isOrgPai) {
          const childOrganograma = {
            ativo: true,
            checked: true,
            dataCriacao: value.dataCriacao,
            id: childId++,
            parentId: 1,
            seqOrganograma: 0,
            sequencial: value.id,
            text: value.dscOrganograma
          };
    
          tipoComunicado['organograma'].push(childOrganograma);
        }
      });
    } else {
      tipoComunicado['organograma'] = null;
    }

    if (this.sistemasAutorizados.length === 0) {
      tipoComunicado['utilizadoSistemasCorporativos'] = false;
      Swal.fire(
        getSwal(
          'error',
          'Erro',
          'Selecione ao menos um sistema que possa utilizar a mensagem.',
          'ok',
          null
        )
      );
    } else {
      tipoComunicado['utilizadoSistemasCorporativos'] = true;
      if (this.edicao) {
        console.log("passou")
        this.comunicadosService
          .editaComunicado(tipoComunicado, this.idComunicado)
          .subscribe(
            res => this.handleResponseEditar(),
            error => this.handleError()
          )
      } else {
        this.comunicadosService
          .criaComunicado(tipoComunicado)
          .subscribe(
            res => this.handleResponse(),
            error => this.handleError()
          );
      }
    }
  }

  desativarComunicado() {

    Swal.fire({
      title: 'Aviso!',
      text:
        'Deseja realmente desativar o comunicado?',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then(result => {
      console.log(result);
      if (result.value) {
        this.comunicadosService.desativarComunicado(this.idComunicado)
          .subscribe(
            res => this.handleResponseDesativar(),
            error => this.handleError()
          )
      }
    });
  }

  handleResponseDesativar() {
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        'Seu tipo de comunicado foi desativado com sucesso',
        'ok',
        null
      )
    );
    this.router.navigate(['/tipos-comunicados']);
  }

  handleResponse() {
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        'Seu tipo de comunicado foi criado com sucesso',
        'ok',
        null
      )
    ).then(res => {
      this.router.navigate(['/tipos-comunicados']);
    })
  }

  handleResponseEditar() {
    this.loading = false;
    Swal.fire(
      getSwal(
        'success',
        'Sucesso!',
        'Seu tipo de comunicado foi editado com sucesso',
        'ok',
        null
      )
    ).then(res => {
      this.router.navigate(['/tipos-comunicados']);
    })
  }

  handleError() {
    this.loading = false;
    Swal.fire(
      getSwal(
        'error',
        'Erro!',
        'Ocorreu um erro, tente novamente mais tarde',
        'ok',
        null
      )
    );
  }

  toggleExpand(item: any) {
    item.expanded = !item.expanded;
    this.ngZone.run(() => {
      this.cd.detectChanges();
    });
  }

  private getOrganograma() {
    this.organogramaService.listaOrganogramaComunicado(this.idComunicado).subscribe(res => {
      let organogramas = [];
  
      res['resultado'].forEach(organograma => {
        const organogramaRaiz = {
          ativo: organograma.ativo,
          checked: organograma.checked,
          id: organograma.sequencia,
          dataCriacao: organograma.dataCriacao,
          dscOrganograma: organograma.dscOrganograma,
          niveis: [],
          expanded: false,
          isPai: true
        };
  
        organograma.niveis.forEach(nivel => {
          const nivelOrganograma = {
            id: nivel.sequenciaNivel,
            dscOrganograma: nivel.dscOrganogramaNivel,
            parentId: organograma.sequencia,
            expanded: false,
            isPai: false, 
            checked: nivel.checked,
            items: []
          };
  
          if (nivel.subnivel && nivel.subnivel.length > 0) {
            nivel.subnivel.forEach(subnivel => {
              const subnivelOrganograma = {
                id: subnivel.sequenciaNivel,
                dscOrganograma: subnivel.dscOrganogramaNivel,
                parentId: nivel.sequenciaNivel,
                expanded: false,
                isPai: false, 
                checked: subnivel.checked,
                items: []
              };
              nivelOrganograma.items.push(subnivelOrganograma);
            });
          }
  
          organogramaRaiz.niveis.push(nivelOrganograma);
        });
  
        organogramas.push(organogramaRaiz);
      });
  
      this.dataOrg = organogramas;
  
      setTimeout(() => {
        this.loading = false;
        this.cd.detectChanges();
      }, 1000);
    });
  }
  
  onOrganogramaChange(organograma: any) {
    organograma.checked = !organograma.checked;
    this.updateCheckedItems();
  }
  
  onNivelChange(nivel: any) {
    nivel.checked = !nivel.checked;
    this.updateCheckedItems();
  }
  
  onSubnivelChange(subnivel: any) {
    subnivel.checked = !subnivel.checked;
    this.updateCheckedItems();
  }
  
  updateCheckedItems() {
    this.checkedItem = [];
    this.dataOrg.forEach(organograma => {
      if (organograma.checked) {
        this.checkedItem.push(organograma);
      }
      organograma.niveis.forEach(nivel => {
        if (nivel.checked) {
          this.checkedItem.push(nivel);
        }
        nivel.items.forEach(subnivel => {
          if (subnivel.checked) {
            this.checkedItem.push(subnivel);
          }
        });
      });
    });
  }

  checkItems() {
    setTimeout(() => {
      let items = this.myTree.getItems();
      this.dataOrganograma.forEach(data => {
        if (data.checked == true) {
          let item = items.filter(element => element['id'] == data.sequencial);
          this.myTree.checkItem(item[0], true);
        }
      })
    }, 1000)
  }

  checkChange(event: any): void {
    let checked = event.args.checked;
    this.checkedItem = this.myTree.getCheckedItems();

    if (this.checkedItem.length > 0) {
      this.inclusaoComunicadoForm.controls['contribuinteInicia'].setValue(false);
      this.inclusaoComunicadoForm.controls['contribuinteInicia'].disable();
    } else {
      this.inclusaoComunicadoForm.controls['contribuinteInicia'].enable();
      this.inclusaoComunicadoForm.controls['contribuinteInicia'].updateValueAndValidity();
    }
  }

  configCaixaTexto() {
    const those = this;
    this.config = {
      disabledDragAndDrop: true,
      disableResizeEditor: false,
      placeholder: '',
      tabsize: 2,
      height: '200px',
      hint: {
        mentions: [
          '{id do representante} - CPF ou CNPJ formatado do representante legal',
          '{representante} - Nome ou razão social do representante legal',
          '{id do contribuinte} - CPF ou CNPJ formatado do contribuinte',
          '{contribuinte} - Nome ou razão social do contribuinte',
          '{id do procurador} - CPF ou CNPJ formatado do procurador',
          '{procurador} - Nome ou razão social do procurador',
          '{data longa} - Data do dia no formado extenso "[dia] do [mês] de [ano]"',
          '{data curta} - Data do dia no formato dd/mm/aaaa'
        ],
        match: /\B@(\w*)$/,
        search: function (keyword, callback) {
          callback($.grep(this.mentions, function (item) {
            return item.indexOf(keyword) == 0;
          }));
        },
        content: function (item) {
          console.log('item', item);
          return '@' + item.replace(/\-[^-]+$/, '');
        }
      },
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['fontsize', ['fontsize']],
        ['fontname', ['fontname']],
        ['color', ['color']],
        ['paragraph'],
        ['height', ['height']],
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Courier New', 'Roboto', 'Times'],
      callbacks: {
        onKeydown: function (e) {
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var t = e.currentTarget.innerText;

          if (e.keyCode === 13) {
            those.caracteres++;
          }

          if (e.keyCode === 8 && totalCaracteres > 0) {
            those.caracteres = totalCaracteres - 1;
          }

          if (totalCaracteres >= 1000) {
            if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey)) {
              e.preventDefault();
            }

          }
        },
        onChange: function (e) {
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          those.caracteres = totalCaracteres;
        },
        onKeyup: function (e) {
          var t = e.currentTarget.innerText;
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          $('#maxContentPost').text(1000 - totalCaracteres + 1);
        },
        onPaste: function (e) {
          var t = e.currentTarget.innerText;
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var bufferText = ((e.originalEvent || e).clipboardData).getData('Text');
          e.preventDefault();
          var maxPaste = bufferText.length;
          if (totalCaracteres + bufferText.length > 1000) {
            maxPaste = 1000 - t.length;
          }
          if (maxPaste > 0) {
            document.execCommand('insertText', false, bufferText.substring(0, maxPaste).trim());
          }
          $('#maxContentPost').text(1000 - t.length);
        }
      }
    }
    $('.note-editable').css('font-size', '16px');
    $('.note-editable').css('font-family', 'Calibri');
    $('.note-current-fontsize').text('14');
    $('.note-current-fontname').text('Calibri');
    $('.note-editable').css('font-weight', 'normal');
    $('.popover > .arrow').css('display', 'block');
    $('.note-hint-popover .popover-content').css('width', 'max-content');
    $('.note-hint-popover').css('border', 'none');
    $('.note-hint-popover .popover-content').css('position', 'absolute');

  }
}
