import { Component, ElementRef, ViewChild } from "@angular/core";
import { PowerbiService } from "src/app/service/powerbi/powerbi.service";
import * as pbi from "powerbi-client";
import { environment } from "src/environments/environment";
import { OrganogramaService } from "src/app/service/organograma/organograma.service";
import { TiposComunicadoService } from "src/app/service/tipos-comunicado/tipos-comunicado.service";
import { ComunicadosService } from "src/app/service/comunicados/comunicados.service";

@Component({
  selector: "app-analise-comunicados",
  templateUrl: "./analise-comunicados.component.html",
  styleUrls: ["./analise-comunicados.component.scss"],
})
export class AnaliseComunicadosComponent {
  public screenHeight: number;

  @ViewChild("reportContainer", { static: false }) reportContainer: ElementRef;

  loading: boolean = true;

  token: string;
  codigoUsuario: String;
  perfilAcesso: number;
  cod_organograma = '';
  cod_organograma_nivel = '';
  cod_tipo_comunicado = '0, ';
  podeEnviarComunicados: boolean;
  cod_tipo_comunicado_modificado: any;
  organogramaComunicado = []; 
  tipoComunicado = []; 
  data: Object = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };
  
  constructor(
    private powerbiService: PowerbiService, 
    private organogramaService: OrganogramaService,
    private tiposComunicadoService: TiposComunicadoService,
    private comunicadosService: ComunicadosService,) {}

  ngOnInit() {
    let session = JSON.parse(localStorage.getItem('session'));
    this.codigoUsuario = session.usuario.codigo;
    this.perfilAcesso = session.perfilAcesso.codigo;
    this.getToken();

    if(this.perfilAcesso !== 0) {
      this.getOrganograma().then((res) => {
        if(res['organograma'] !== 0 && res['nivel'] !== 0) {
          this.getTiposComunicados().then((data) => {
            setTimeout(() => {
              this.showReport(this.token);
            }, 5000)
            }
            );
        } else {
          this.cod_tipo_comunicado = '0, ' //0 tras todos (sem filtro)
        }
      });
    } else {
      this.cod_organograma = '0';
      this.cod_organograma_nivel = '0';
      this.cod_tipo_comunicado = '0, ' //0 tras todos (sem filtro)

      setTimeout(() => {
        this.showReport(this.token);
      }, 5000);
    }
  }

  //Organograma do usuário logado
  getOrganograma() {
    return new Promise((resolve, reject) => {
      let organogramaPai;
      let organograma = [];
      let organogramaNivel = [];

      this.organogramaService.getListagemOrganogramas(this.codigoUsuario).subscribe(res => {
        let organogramas = res['resultado'];
  
        organogramas.forEach(value => {
          value.forEach(data => {
            console.log("getOrganograma ", data)
            if(data.parentId == 0) {
              organogramaPai = data.sequencial;
            }

            if(data.parentId !== 0) {
              if(data.checked == true) {
                organograma.push(organogramaPai);
                organogramaNivel.push(data.sequencial);
              }
            }
          });
        });

        organograma = [...new Set(organograma)];
        organogramaNivel = [...new Set(organogramaNivel)];

        organograma.forEach(data => {
          this.cod_organograma = this.cod_organograma.concat(`${JSON.stringify(data)}, `);
        });

        organogramaNivel.forEach(data => {
          this.cod_organograma_nivel = this.cod_organograma_nivel.concat(`${JSON.stringify(data)}, `);
        })
      });
  
      setTimeout(() => {
        if(this.cod_organograma == '') {
          this.cod_organograma = '0';
        } else {
          this.cod_organograma = this.cod_organograma.substring(0, this.cod_organograma.length - 2);
        }
  
        if(this.cod_organograma_nivel == '') {
          this.cod_organograma_nivel = '0';
        } else {
          this.cod_organograma_nivel = this.cod_organograma_nivel.substring(0, this.cod_organograma_nivel.length - 2);
        }

        let resultado = {
          organograma: this.cod_organograma,
          nivel: this.cod_organograma_nivel
        }
        resolve(resultado);
      }, 500);
    });
  }

  getTiposComunicados() {  
    return new Promise<void>((resolve, reject) => {
      this.tipoComunicado = [];
      this.comunicadosService
      .getComunicadosAtivoInativo(this.codigoUsuario).subscribe((res) => {
        res["resultado"].forEach((comunicado) => {
            // if (
            //   comunicado.auditorInicia == true &&
            //   comunicado.emUso == true &&
            //   comunicado.descricao.includes("teste") == false &&
            //   comunicado.objetivo.toLowerCase().includes("teste") == false
            // ) {
              this.tipoComunicado.push(comunicado.codigo.toString());
            // }
        });        
          console.log('Resposta:', res);
          if (this.tipoComunicado.length !== 0) {
            this.podeEnviarComunicados = true;
          }
          resolve();
        },
        err => {
          console.error('Erro:', err);
          reject(err);
        }
      );
    });
  }

  getOrganogramaTipoComunicado(tiposComunicados) {
    tiposComunicados.forEach(value => {
      let organogramaComunicado = '';
      let organogramaNivel = '';
      
      this.organogramaService.listaOrganogramaComunicado(value).subscribe(res => {
        res['resultado'].forEach(organograma => {
          organograma.forEach(data => {
            if(data.checked == true && data.parentId == 0) {
              organogramaComunicado = organogramaComunicado.concat(`${JSON.stringify(data.sequencial)}, `);
            } else if(data.checked == true && data.parentId !== 0) {
              organogramaNivel = organogramaNivel.concat(`${JSON.stringify(data.sequencial)}, `);
            }
          });

          organogramaComunicado = organogramaComunicado.substring(0, organogramaComunicado.length - 2);
          organogramaNivel = organogramaNivel.substring(0, organogramaNivel.length - 2);

          if((organogramaComunicado == this.cod_organograma) && (organogramaNivel == this.cod_organograma_nivel)) {
            if(this.cod_tipo_comunicado == '0, ') {
              this.cod_tipo_comunicado = '';
            }
            this.cod_tipo_comunicado = this.cod_tipo_comunicado.concat(`${JSON.stringify(value)}, `);
          }
          console.log('cod_org_comunicado: ', this.cod_tipo_comunicado)
        });
      });
    });
    
    return this.cod_tipo_comunicado;
  }

  showReport(accessToken) {
    let embedUrl;
    if(this.perfilAcesso == 0) {
      embedUrl = 
        "https://app.powerbi.com/reportEmbed?reportId="+environment.analiseComunicados+"&groupId="+environment.groupId+
        "&filter=comunicado/id_municipio eq " + environment.powerbi + " and comunicado/cod_organograma in " +  `(${this.cod_organograma})`+ 
        " and comunicado/cod_organograma_nivel in " + `(${this.cod_organograma_nivel})` + "&schema="+environment.schema;
    } else {
      console.log('organogramaComunicado: ', this.organogramaComunicado)
      embedUrl = 
      "https://app.powerbi.com/reportEmbed?reportId="+environment.analiseComunicados+"&groupId="+environment.groupId+
      "&filter=comunicado/id_municipio eq " + environment.powerbi + " and comunicado/cod_tipo_comunicado in " + `(${this.tipoComunicado.join(',')})` 
      + "&schema="+environment.schema;
    }

    let embedReportId = environment.analiseComunicados;
    let config: pbi.IEmbedConfiguration = {
      type: "report",
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: embedReportId,
      settings: {},
    };
    let reportContainer = <HTMLElement>this.reportContainer.nativeElement;
    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    let report = <pbi.Report>powerbi.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
      this.loading = false;
      console.log("Loaded");
    });

    report.on("error", () => {
      this.getToken();
    });
    this.loading = false;
  }

  getToken() {
    this.powerbiService.getToken()
      .subscribe((res) => {
        this.token = res["resultado"];
      });
  } 
}
