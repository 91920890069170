import { AfterViewInit, Component, OnInit, ViewChild, ViewChildren, NgZone } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  MinLengthValidator,
  AbstractControl,
  MaxLengthValidator,
} from '@angular/forms';
import { GerenciarUsuariosService } from '../../../../../service/configuracao/gerenciar-usuarios.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { clearEmptyValues } from '../../../../../utils/cleanObjectToSave';
//import { CepService } from '../../../../../service/cep/cep.service';
import { CpfCnpjPipe } from '../../../../../pipes/cpf-cnpj.pipe';
import { CgfPipe } from '../../../../../pipes/cgf.pipe';
import { ProcuracoesService } from 'src/app/service/procuracoes/procuracoes.service';
import { AmbienteService } from '../../../../../service/ambiente/ambiente.service';
import { ConsultaUsuarioService } from 'src/app/service/consulta-usuario/consultar.user.service';
import { OrganogramaService } from "src/app/service/organograma/organograma.service";
import { jqxTreeComponent } from "jqwidgets-ng/jqxtree";
import { ChangeDetectorRef } from '@angular/core';
import { SessionService } from 'src/app/service/session/session.service';
declare var $;

@Component({
  providers: [CgfPipe],
  selector: 'app-editar-usuario',
  templateUrl: 'editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss'],
})
export class EditarUsuarioComponent implements OnInit, AfterViewInit {
  @ViewChild('myTree', { static: false }) myTree: jqxTreeComponent;

  editarUsuarioForm: FormGroup;
  form: FormGroup;
  routeSubscription: Subscription;
  loading: boolean = true;
  usuario: Object = {
    detalhe: {},
  };

  situacaoUsuario: any;

  codigoUsuario: number = null;
  labels: any;
  //lastCepSearch: string;
  tipoUsuario: any;
  representanteLegal: any;
  cpfRepresentanteLegal: any;
  cnpj: number;
  cnpjcpfErro: boolean;
  celular: any;
  auditorAdministrador: boolean = false;
  mostrarOrganograma: boolean = false;
  mostrarOpcaoSituacao: boolean = false;
  ativacaoPorOficio: boolean = false;
  apagarEmpresa: boolean = false;
  enviaTelefone1: boolean = false;
  enviaTelefone2: boolean = false;
  enviaEmail1: boolean = false;
  enviaEmail2: boolean = false;
  motivoAtivacaoOficio: string;
  exigeCertificadoCnpj: any;
  dadosCliente: any;
  codigoCliente: any;
  personalidadeJuridica: any;
  setorAtuacao: any;
  tipoIdentificacao: any;
  codigoEmpresa: any;
  codUser: any;
  usuarioInvalido: any;
  ambiente: any;
  source = {
    datatype: 'json',
    datafields: [
      { name: 'id' },
      { name: 'parentId' },
      { name: 'text' }
    ],
    id: 'id',
    localdata: null
  };
  dataAdapter = new jqx.dataAdapter(this.source);
  records: any = this.dataAdapter.getRecordsHierarchy('id', 'parentId', 'items', [{ name: 'text', map: 'label' }]);
  checkedItem: any = [];
  organograma: any = [];
  data: any = [];
  dataOrganograma: any = [];
  listaOrganograma: any[] = [];
  dataOrg: any[] = [];
  expandedItems = new Set<number>();

  public semMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  public menasMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  public cpfMask = [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/
  ];
  public cnpjMask = [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];


  perfisTipos = [
    {
      codigo: 3,
      descricao: 'PROCURADOR',
      ativo: true,
      perfilAtivo: false,
      exibirPerfil: true,
      procuracoes: null,
      podeTrocar: false,
    },
    {
      codigo: 2,
      descricao: 'CONTRIBUINTE',
      ativo: true,
      procuracoes: null,
      perfilAtivo: false,
      exibirPerfil: true,
      podeTrocar: true,
    },
    {
      codigo: 0,
      descricao: 'ADMINISTRADOR DA PLATAFORMA',
      ativo: true,
      procuracoes: null,
      perfilAtivo: false,
      exibirPerfil: true,
      podeTrocar: true,
    },
    {
      codigo: 1,
      descricao: 'AUDITOR FISCAL',
      ativo: true,
      procuracoes: null,
      perfilAtivo: false,
      exibirPerfil: true,
      podeTrocar: true,
    },
    {
      codigo: 4,
      descricao: 'ENCARREGADO',
      ativo: true,
      procuracoes: null,
      perfilAtivo: false,
      exibirPerfil: true,
      podeTrocar: true,
    },
    {
      codigo: 5,
      descricao: 'ADMINISTRATIVO',
      ativo: true,
      procuracoes: null,
      perfilAtivo: false,
      exibirPerfil: true,
      podeTrocar: true,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private gerenciarUsuariosService: GerenciarUsuariosService,
    private pipeDoc: CpfCnpjPipe,
    private cgfPipe: CgfPipe,
    private procuracoesService: ProcuracoesService,
    private ambienteService: AmbienteService,
    private consultaUsuario: ConsultaUsuarioService,
    private organogramaService: OrganogramaService,
    private sessionService: SessionService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    this.setLabels();
    this.form = this.fb.group({
      organogramaChecked: [false],
      nivelChecked: [false],
      subnivelChecked: [false]
    });
  }

  ngOnInit() {
    this.codUser = this.sessionService.getSession();
    this.codUser = this.codUser.usuario.codigo;
    this.obterDadosAmbiente();
    this.verificarCertificadoCnpj();
    this.personalidadesJuridicasAtivos();
    this.setorAtuacaoAtivos();
    this.criarFormulario();
    this.representanteLegalObrigatorio()
    this.routeSubscription = this.route.params.subscribe(params => {
      if (params['id']) {
        this.codigoUsuario = params['id'];
        this.gerenciarUsuariosService
          .obterUsuario(this.codigoUsuario)
          .subscribe(
            res => {
              this.usuario = res;
              
              this.mostrarOrganograma = res.perfis.some(item =>
                item.codigo == 4 ||
                item.codigo == 1 ||
                item.codigo == 5
              );
              
              const codigosValidos = res.perfis.map(item => item.codigo);

              this.mostrarOpcaoSituacao =
                (codigosValidos.length === 1 && (codigosValidos[0] === 2 || codigosValidos[0] === 3)) ||
                (codigosValidos.includes(2) && codigosValidos.includes(3) && codigosValidos.length === 2);

              this.codigoEmpresa = res.codigo;
              this.editarUsuarioForm.controls['dataCriacao'].patchValue(
                moment(res['dataCriacao']).format('DD/MM/YYYY') || '',
              );
              this.editarUsuarioForm.controls['nomeRazaoSocial'].patchValue(
                res['nomeRazaoSocial'],
              );
              this.editarUsuarioForm.controls['cpfCnpj'].patchValue(
                res['cpfCnpj'],
              );
              this.editarUsuarioForm.controls['criouSenha'].patchValue(
                res['criouSenha'],
              );

              this.editarUsuarioForm.controls['situacaoUsuario'].patchValue(
                res['detalhe'] !== null && res['detalhe']['situacaoUsuario'] !== null
                  ? res['detalhe']['situacaoUsuario']
                  : '',
              );

              this.editarUsuarioForm.controls['tipoIdentificacao'].patchValue(
                res['tipoIdentificacao'],
              );
              this.editarUsuarioForm.controls['situacao'].patchValue(
                res['situacao'],
              );
              this.editarUsuarioForm.controls['enviaEmail1'].patchValue(
                res['enviaEmail1'],
              );
              this.editarUsuarioForm.controls['enviaTelefone1'].patchValue(
                res['enviaTelefone1'],
              );
              this.editarUsuarioForm.controls['ativacaoPorOficio'].patchValue(
                res['ativacaoPorOficio'],
              );
              this.editarUsuarioForm.controls['apagarEmpresa'].patchValue(false);


              this.editarUsuarioForm.controls['motivoAtivacaoOficio'].patchValue(
                res['motivoAtivacaoOficio'],
              );
              this.editarUsuarioForm.controls['inscricaoMunicipal'].patchValue(
                res['detalhe'] !== null && res['detalhe']['inscricaoMunicipal'] !== null
                  ? res['detalhe']['inscricaoMunicipal']
                  : '',
              );
              this.editarUsuarioForm.controls['ativo'].patchValue(
                res['detalhe'] !== null && res['detalhe']['ativo'] !== null
                  ? res['detalhe']['ativo']
                  : '',
              );
              this.editarUsuarioForm.controls['celular'].patchValue(
                (res['ddd'] !== null ? res['ddd'] : '') +
                (res['celular'] !== null ? res['celular'] : ''),
              );
              this.editarUsuarioForm.controls['email'].patchValue(res['email']);
              this.editarUsuarioForm.controls['documentoLink'].patchValue(
                res['detalhe'] !== null && res['detalhe']['documentoLink'] !== null
                  ? res['detalhe']['documentoLink']
                  : '',
              );
              this.editarUsuarioForm.controls['personalidadeJuridica'].patchValue(
                res['detalhe'] !== null && res['detalhe']['personalidadeJuridica']['codigo'] !== null
                  ? res['detalhe']['personalidadeJuridica']['codigo']
                  : '',
              );
              this.editarUsuarioForm.controls['setorAtuacao'].patchValue(
                res['detalhe'] !== null && res['detalhe']['setorAtuacao']['codigo'] !== null
                  ? res['detalhe']['setorAtuacao']['codigo']
                  : '',
              );
              this.tipoUsuario = this.editarUsuarioForm.controls['tipoIdentificacao'].value;
              this.representanteLegalObrigatorio();
              this.getRepresentanteLegal();
              this.montaPerfis();
              
              if (this.codigoUsuario != this.codUser) {
                this.usuarioInvalido = false;
              } else {
                this.usuarioInvalido = true;
              }
              this.cd.detectChanges();
              if (this.usuarioInvalido) {
                this.editarUsuarioForm.controls['apagarEmpresa'].disable();
              } else {
                this.editarUsuarioForm.controls['apagarEmpresa'].enable();
              }

            },
            error => {
              this.loading = false;
              console.log(error);
            },
          );
      }
    });
  }

  ngAfterViewInit() {
    this.getOrganograma();
  }
  
  obterDadosAmbiente() {
    this.dadosCliente = JSON.parse(localStorage.getItem('session'));
    this.codigoCliente = this.dadosCliente.cliente.codigo;

    const urlsPermitidas = [
      'dteauditor.qa.giss.com.br',
      'dteauditor.dev.giss.com.br',
      'dte.hmg.giss.com.br',
      'dteauditor.giss.com.br',
      'localhost'
    ];

    this.ambienteService.obterDadosAmbiente(this.codigoCliente).subscribe(
      res => {
        const hostname = window.location.hostname.split(':')[0];
        if (urlsPermitidas.includes(hostname)) {
          this.ambiente = true;
        } else {
          this.ambiente = false;
        }

      }, err => {
        console.log(err.error.text);
      });
  }

  montaPerfis() {
    let currentPerfil;
    let isProcuradorOuContribuinte = false;
    let isAdministrador = false;
    let isAuditor = false;

    let admIndex = 0;
    let contribuinteIndex = 0;
    let procuradorIndex = 0;
    let auditorIndex = 0;

    let perfisTmp = this.perfisTipos;

    perfisTmp.forEach((perfil, index) => {
      currentPerfil = this.getPerfilPorCodigo(perfil.codigo);
      if (currentPerfil !== null) {
        if (
          currentPerfil.descricao.toLowerCase().indexOf('administrador') !== -1
        ) {
          isAdministrador = true;
        }
        if (
          currentPerfil.descricao.toLowerCase().indexOf('procurador') !== -1 ||
          currentPerfil.descricao.toLowerCase().indexOf('contribuinte') !== -1
        ) {
          isProcuradorOuContribuinte = true;
        }
        if (
          currentPerfil.descricao.toLowerCase().indexOf('auditor fiscal') !== -1
        ) {
          isAuditor = true;
        }
        if (
          currentPerfil.descricao.toLowerCase().indexOf('encarregado') !== -1
        ) {
          isAuditor = true;
        }
        if (
          currentPerfil.descricao.toLowerCase().indexOf('administrativo') !== -1
        ) {
          isAuditor = true;
        }
        
        perfil.perfilAtivo = true;
        perfil.exibirPerfil = true;
        if (currentPerfil.procuracoes !== null) {
          let procuracoesEncontradas = this.montaProcuracoes(
            currentPerfil.procuracoes,
          );
          perfil.procuracoes =
            procuracoesEncontradas.length > 0 ? procuracoesEncontradas : null;
        }
      }
    });

    this.perfisTipos = perfisTmp;

    if (isAuditor || isAdministrador) {
      this.auditorAdministrador = true;

      this.editarUsuarioForm.controls['personalidadeJuridica'].patchValue('0');
      this.editarUsuarioForm.controls['setorAtuacao'].patchValue('0');
    }

    console.log(' >> result', this.perfisTipos);
  }

  isCelular(): boolean {
    return this.celular == null ? true : this.celular.length > 11 ? true : false;
  }

  getTelefone(): string {
    return this.isCelular() ? '(00)00000-0000' : '(00)0000-0000';
  }

  montaProcuracoes(procuracoes): any {
    let result: Array<any> = [];
    let procuracoesTipos = {};
    procuracoes.forEach(procuracao => {
      if (!procuracoesTipos[procuracao.tipoProcuracao.descricao]) {
        procuracoesTipos[procuracao.tipoProcuracao.descricao] = {
          label: procuracao.tipoProcuracao.descricao,
          procuracoesOutorgadas: [],
        };
      }
      //dados para lista de usuarios > CNPJ ou CPF (com máscara) - Inscrição (na versão da SEFAZ com máscara)- Razão Social ou Nome - Data da Outorga - Data Fim da Procuração
      let procuracaoIten = {
        nome: procuracao['outorgante'].nomeRazaoSocial,
        user: this.codigoUsuario,
        procuracao: procuracao.codigo,
        documento: this.pipeDoc.transform(procuracao['outorgante'].cpfCnpj),
        ie:
          procuracao['outorgante'].detalhe &&
            procuracao['outorgante'].detalhe['inscricaoMunicipal']
            ? this.cgfPipe.transform(
              procuracao['outorgante'].detalhe['inscricaoMunicipal'],
            )
            : '',
        inicio:
          procuracao['outorgante'].dataCriacao &&
            procuracao['outorgante'].dataCriacao !== null
            ? moment(procuracao['outorgante'].dataCriacao).format(
              'DD/MM/YYYY HH:mm:ss',
            )
            : '',
        fim:
          procuracao['outorgante'].dataInativacao === null
            ? ''
            : moment(procuracao['outorgante'].dataInativacao).format(
              'DD/MM/YYY HH:mm:ss',
            ),
      };
      procuracoesTipos[
        procuracao.tipoProcuracao.descricao
      ].procuracoesOutorgadas.push(procuracaoIten);
    });
    Object.keys(procuracoesTipos).forEach(tipoProcuracao => {
      result.push(procuracoesTipos[tipoProcuracao]);
    });
    return result;
  }

  getPerfilPorCodigo(cod): any {
    let perfil = null;
    this.usuario['perfis'].forEach(perf => {
      if (perf.codigo === cod) {
        perfil = perf;
      }
    });
    return perfil;
  }

  criarFormulario() {
    this.editarUsuarioForm = this.fb.group({
      dataCriacao: [{ value: '', disabled: true }],
      ativo: '',
      criouSenha: [{ value: '', disabled: true }],

      situacaoUsuario: ['', Validators.required],

      nomeRazaoSocial: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(160),
        ]),
      ],
      cpfCnpj: [{ value: '', disabled: true }],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      celular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(11),
        ]),
      ],
      tipoIdentificacao: [{ value: '', disabled: true }],
      situacao: '',
      inscricaoMunicipal: [
        '',
        Validators.compose([Validators.minLength(2), Validators.maxLength(30)]),
      ],
      cpfRepresentanteLegal: [
        ''
      ],
      nomeRepresentanteLegal: [
        '',
      ],
      emailRepresentanteLegal: [
        ''
      ],
      celularRepresentanteLegal: [
        ''
      ],
      ativacaoPorOficio: false,
      apagarEmpresa: [false, Validators.required],
      enviaTelefone1: false,
      enviaEmail1: false,
      motivoAtivacaoOficio: '',
      documentoLink: [{ value: '', disabled: true }],
      personalidadeJuridica: [
        ''
      ],
      setorAtuacao: [
        ''
      ],
      personalidadeJuridicaRepresentante: [
        ''
      ],
      setorAtuacaoRepresentante: [
        ''
      ]
    });
  }

  // TODO organizar as regras e aplicar validação para liberação ou bloqueio dos botões
  isFormInValid() {
    return (
      this.editarUsuarioForm.invalid &&
      (!this.editarUsuarioForm.dirty || !this.editarUsuarioForm.touched)
    );
  }

  private customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } {
    const emailError = Validators.email(control);
    if (control.value && emailError) {
      return { email: {} };
    }

    return null;
  }


  editarUsuario(recuperaSenha) {
    this.loading = true;
    const form = this.editarUsuarioForm.getRawValue();
    form['cpfRepresentanteLegal'] = form['cpfRepresentanteLegal'].replace(/[^\d]+/g, "");
    if (this.exigeCertificadoCnpj == 0) {
      form['personalidadeJuridica'] = "0";
      form['setorAtuacao'] = "0"
      if (this.tipoIdentificacao == 2) {
        form['personalidadeJuridicaRepresentante'] = "0";
        form['setorAtuacaoRepresentante'] = "0"
      }

    }

    console.log(' >> salvar alteracoes user : ', this.perfisTipos);
    console.log(' >> salvar alteracoes user : ', form);

    let userToSave = {
      codigo: this.usuario['codigo'],
      cpfCnpj: form['cpfCnpj'],
      criouSenha: form['criouSenha'],

      dataCriacao: this.usuario['dataCriacao'],
      dataInativacao: this.usuario['dataInativacao'],
      ddd: form['celular'].length >= 9 ? form['celular'].substring(0, 2) : '',
      celular:
        form['celular'].length >= 9
          ? form['celular'].substring(2, form['celular'])
          : '',
      detalhe: {
        ativo: true,
        codigo:
          this.usuario['detalhe'] !== null
            ? this.usuario['detalhe']['codigo']
            : null,
        dataAlteracao:
          this.usuario['detalhe'] !== null
            ? this.usuario['detalhe']['dataAlteracao']
            : null,
        dataCriacao:
          this.usuario['detalhe'] !== null
            ? this.usuario['detalhe']['dataCriacao']
            : null,
        documentoLink: form['documentoLink'],
        inscricaoMunicipal: form['inscricaoMunicipal'],
        situacaoUsuario: form['situacaoUsuario'],
        personalidadeJuridica: {
          codigo: form['personalidadeJuridica'],
        },
        setorAtuacao: {
          codigo: form['setorAtuacao']
        }
      },
      email: form['email'],
      imagem: this.usuario['imagem'],
      nomeRazaoSocial: form['nomeRazaoSocial'],
      situacao: form['situacao'],
      tipoIdentificacao: form['tipoIdentificacao'],
      ativacaoPorOficio: form['ativacaoPorOficio'],
      apagarEmpresa: form['apagarEmpresa'],
      enviaTelefone1: form['enviaTelefone1'],
      enviaEmail1: form['enviaEmail1'],

      motivoAtivacaoOficio: form['motivoAtivacaoOficio'],
      cpfRepresentanteLegal: form['cpfRepresentanteLegal'],
      nomeRepresentanteLegal: form['nomeRepresentanteLegal'],
      emailRepresentanteLegal: form['emailRepresentanteLegal'],
      dddRepresentanteLegal: form['celularRepresentanteLegal'].length >= 9 ? form['celularRepresentanteLegal'].substring(0, 2) : '',
      celularRepresentanteLegal: form['celularRepresentanteLegal'].length >= 9
        ? form['celularRepresentanteLegal'].substring(2, form['celularRepresentanteLegal'])
        : '',
      personalidadeJuridica: {
        codigo: form['personalidadeJuridicaRepresentante']
      },
      setorAtuacao: {
        codigo: form['setorAtuacaoRepresentante']
      },

    };

    clearEmptyValues(userToSave);
    clearEmptyValues(userToSave.detalhe);

    let hasDetalhe = false;

    Object.keys(userToSave.detalhe).map(key => {
      if (key !== 'ativo') {
        hasDetalhe = userToSave.detalhe[key] === null ? hasDetalhe : true;
      }
    });

    if (!hasDetalhe) {
      userToSave.detalhe = null;
    }

    userToSave['perfis'] = [];

    this.perfisTipos.forEach(value => {
      if (value.perfilAtivo === true) {
        userToSave['perfis'].push({ codigo: value.codigo });
      }
    });

    userToSave['organograma'] = [];

    if (this.auditorAdministrador == true && this.checkedItem.length !== 0) {    
      let childId = 2;
      this.checkedItem.forEach((value, index) => {
    
        const isOrgPai = value.isPai == true;
        let parentOrganograma = null;
    
        if (isOrgPai) {
          parentOrganograma = {
            ativo: true,
            checked: true,
            dataCriacao: value.dataCriacao,
            id: 1,
            parentId: 0,
            seqOrganograma: 0,
            sequencial: value.id,
            text: value.dscOrganograma
          };
    
          userToSave['organograma'].push(parentOrganograma);
        }
    
        if (!isOrgPai) {
          const childOrganograma = {
            ativo: true,
            checked: true,
            dataCriacao: value.dataCriacao,
            id: childId++,
            parentId: 1,
            seqOrganograma: 0,
            sequencial: value.id,
            text: value.dscOrganograma
          };
    
          userToSave['organograma'].push(childOrganograma);
        }
      });
    } else {
      userToSave['organograma'] = null;
    }
    this.gerenciarUsuariosService
      .editarUsuario(this.codigoUsuario, recuperaSenha, userToSave)
      .subscribe(
        res => {
          this.loading = false;
          /*Swal.fire('Sucesso!', 'Edição realizada com êxito', 'success')*/
          if (recuperaSenha) {
            Swal.fire(
              getSwal(
                'success',
                'Sucesso!',
                'E-mail para recuperar senha enviado!',
                'ok',
                null,
              ),
            ).then(res => {
              this.router.navigate([`gestao-usuario/consultar`]);
            });
          } else {
            Swal.fire(
              getSwal(
                'success',
                'Sucesso!',
                'Edição realizada com êxito',
                'ok',
                null,
              ),
            ).then(res => {
              this.router.navigate([`gestao-usuario/consultar`]);
            });
          }
        },
        err => {
          this.loading = false;
          // Swal.fire('Ops!', err.error.excecao.recomendacao, 'error');
          Swal.fire(
            getSwal(
              'error',
              'Ops!',
              err.error.excecao.recomendacao,
              'ok',
              null,
            ),
          );
        },
      );
  }

  mask(): any {
    return {
      mask: value => {
        value = value.replace(/[^\d]+/g, '');
        if (value.length <= 5) {
          return this.semMask;
        } else if (value.length <= 9) {
          return this.menasMask;
        } else if (value.length >= 10 && value.length <= 11) {
          return this.cpfMask;
        } else if (value.length >= 13 && value.length <= 16) {
          return this.cnpjMask;
        } else if (value.length <= 11) {
          return this.cpfMask;
        }
      },
      guide: false
    };
  }

  getRepresentanteLegal() {
    this.cnpj = this.editarUsuarioForm.controls['cpfCnpj'].value;
    this.procuracoesService.getRepresentanteLegal(this.cnpj)
      .subscribe(res => {
        this.representanteLegal = res;
        if (this.representanteLegal != null) {
          if (this.representanteLegal['resultado']['outorgante'] != null) {
            this.editarUsuarioForm.controls['cpfRepresentanteLegal'].patchValue(
              this.representanteLegal['resultado']['outorgante']['cpfCnpj'] != null ?
                this.representanteLegal['resultado']['outorgante']['cpfCnpj'] : null
            );
            this.editarUsuarioForm.controls['nomeRepresentanteLegal'].patchValue(
              this.representanteLegal['resultado']['outorgante']['nomeRazaoSocial']
            );
            this.editarUsuarioForm.controls['emailRepresentanteLegal'].patchValue(
              this.representanteLegal['resultado']['outorgante']['email']
            );
            this.editarUsuarioForm.controls['celularRepresentanteLegal'].patchValue(
              (this.representanteLegal['resultado']['outorgante']['ddd']) +
              (this.representanteLegal['resultado']['outorgante']['celular'])
            );
            this.editarUsuarioForm.controls['personalidadeJuridicaRepresentante'].patchValue(
              this.representanteLegal['resultado']['outorgante']['detalhe']['personalidadeJuridica']['codigo']
            );
            this.editarUsuarioForm.controls['setorAtuacaoRepresentante'].patchValue(
              this.representanteLegal['resultado']['outorgante']['detalhe']['setorAtuacao']['codigo']
            )
          }
        }
      })
  }

  get f() { return this.editarUsuarioForm.controls }

  telefoneMask(celular) {
    // const isTelefone = celular = null ? true : celular.length < 11 ? true : false;
    // return isTelefone ? '(00) 0000-00009' : '(00) 00000-0000';
  }

  tornarCampoObrigatorio() {
    if (this.editarUsuarioForm.controls.ativacaoPorOficio.value == true) {
      this.editarUsuarioForm.controls.motivoAtivacaoOficio.setValidators([Validators.required, Validators.minLength(10)]);
    } else {
      this.editarUsuarioForm.get('motivoAtivacaoOficio').clearValidators();
    }
    this.editarUsuarioForm.get('motivoAtivacaoOficio').updateValueAndValidity();
  } 

  async CampoApagarEmpresa() {
    if (this.editarUsuarioForm.controls.apagarEmpresa.value == true) {
      let cpf_cnpj = this.usuario['cpfCnpj']
      let nome_razao_social = this.usuario['nomeRazaoSocial'];
      let cpfCnpjLenght = this.usuario['cpfCnpj'].length

      const { value: inputText, dismiss } = await Swal.fire({
        html: `Esta ação é irreversível e todos os dados relacionados ao CPF/CNPJ ${cpf_cnpj} - Nome/Razão Social ${nome_razao_social} serão apagados. Digite abaixo o CPF/CNPJ para confirmar a ação.`,
        input: "text",
        icon: "warning",
        inputPlaceholder: "Digite o CPF/CNPJ para confirmar a ação",
        confirmButtonText: 'Prosseguir',
        cancelButtonText: 'Desistir',
        showCancelButton: true,
        onOpen: () => {
          Swal.getInput().addEventListener('keydown', function (e) {
            if (e.ctrlKey || e.metaKey || e.altKey || (e.keyCode >= 35 && e.keyCode <= 40) || e.keyCode == 8 || e.keyCode == 46) {
              return;
            }
            if (!/[0-9]/.test(e.key) || this.value.length >= cpfCnpjLenght) {
              e.preventDefault();
            }
          });
        },
        inputValidator: (value) => {
          let msgLimpa = value.replace("-", "").replace(".", "").replace(".", "").replace("/", "");
          if (!msgLimpa) {
            return "Você precisa escrever algo!";
          }
          else if ((msgLimpa.length > cpfCnpjLenght) || msgLimpa !== cpf_cnpj) {
            return "CPF/CNPJ inválido!"
          }
        }
      });

      if (dismiss === Swal.DismissReason.cancel) {
        this.editarUsuarioForm.controls['apagarEmpresa'].patchValue(false);
        return;
      } else {
        this.loading = true;
        this.consultaUsuario.deletarUsuario(this.codigoEmpresa).subscribe(res => {
          this.loading = false;
          this.router.navigate([`gestao-usuario/consultar`]);
        }, error => {
          this.loading = false;
          Swal.fire(
            getSwal(
              'error',
              error.error.excecao.recomendacao,
              '',
              'ok',
              null
            )
          );
        })
      }
    } else if (this.editarUsuarioForm.controls.apagarEmpresa.value == false) {
      return;
    }
  }

  representanteLegalObrigatorio() {
    if (this.tipoUsuario == 2) {
      this.editarUsuarioForm.controls.nomeRepresentanteLegal.setValidators(Validators.required);
      this.editarUsuarioForm.controls.emailRepresentanteLegal.setValidators(Validators.required);
      this.editarUsuarioForm.controls.celularRepresentanteLegal.setValidators(Validators.required);
      //this.editarUsuarioForm.controls.cpfRepresentanteLegal.setValidators (Validators.compose([Validators.required,Validators.minLength(14), Validators.maxLength(18)])),
      this.editarUsuarioForm.get('nomeRepresentanteLegal').updateValueAndValidity();
      this.editarUsuarioForm.get('emailRepresentanteLegal').updateValueAndValidity();
      this.editarUsuarioForm.get('celularRepresentanteLegal').updateValueAndValidity();
      //this.editarUsuarioForm.get('cpfRepresentanteLegal').updateValueAndValidity();


      if (this.exigeCertificadoCnpj == 1) {
        this.editarUsuarioForm.controls.personalidadeJuridicaRepresentante.setValidators(Validators.required);
        this.editarUsuarioForm.controls.setorAtuacaoRepresentante.setValidators(Validators.required);

        this.editarUsuarioForm.get('personalidadeJuridicaRepresentante').updateValueAndValidity();
        this.editarUsuarioForm.get('setorAtuacaoRepresentante').updateValueAndValidity();

      }
    }
  }

  consultarUsuario(id: string) {
    this.gerenciarUsuariosService.getApiExterna(id).subscribe(res => {
    }
      , error => {
        if (error.error.excecao.recomendacao != "") {
          this.cnpjcpfErro = true
          Swal.fire(
            getSwal(
              'error',
              error.error.excecao.recomendacao,
              '',
              'ok',
              null
            )
          );
        }
        else {
          this.cnpjcpfErro = false
        }
      })
  }

  validarCPF() {
    let cpfcnpj = this.editarUsuarioForm.controls.cpfRepresentanteLegal.value.replace(/[^\d]+/g, "")
    if (cpfcnpj.length <= 11 && cpfcnpj.length === 11) {
      return;
    }
    if (cpfcnpj.length <= 11) {
      this.editarUsuarioForm.controls.cpfRepresentanteLegal.setValidators(Validators.compose(
        [Validators.required, Validators.minLength(14)]))
      this.editarUsuarioForm.controls.cpfRepresentanteLegal.updateValueAndValidity();
      return false;
    } else {
      this.editarUsuarioForm.controls.cpfRepresentanteLegal.setValidators(Validators.compose(
        [Validators.required, Validators.minLength(18), Validators.maxLength(18)]))
      this.editarUsuarioForm.controls.cpfRepresentanteLegal.updateValueAndValidity();
      return false;
    }
  }

  ajuda() {
    Swal.fire({
      html: 'Se estiver em Sim, o usuário, assim como os comunicados trocados por este, não irão ser contabilizados em relatórios e consultas.',
      confirmButtonText: 'Fechar'
    })
  }

  verificarCertificadoCnpj() {
    this.dadosCliente = JSON.parse(localStorage.getItem('session'));
    this.codigoCliente = this.dadosCliente.cliente.codigo;

    this.ambienteService.obterDadosAmbiente(this.codigoCliente).subscribe(
      res => {
        this.exigeCertificadoCnpj = res.resultado.configuracao.exigeCertificadoCnpj;
        console.log("exige certificado: ", this.exigeCertificadoCnpj)
        if (this.exigeCertificadoCnpj == 0) {
          //this.editarUsuarioForm.removeControl('personalidadeJuridica');
          //this.editarUsuarioForm.removeControl('setorAtuacao');
        }
      }
    )
  }

  personalidadesJuridicasAtivos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        this.personalidadeJuridica = res.resultado;
        //console.log("personalidade: ", this.personalidadeJuridica)
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  setorAtuacaoAtivos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        this.setorAtuacao = res.resultado;
      },
      error => {
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades jurídicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  toggleExpand(item: any) {
    item.expanded = !item.expanded;
    this.ngZone.run(() => {
      this.cd.detectChanges();
    });
  }
  
  private getOrganograma() {
    this.organogramaService.getListagemOrganogramas(this.codigoUsuario).subscribe(res => {
      let organogramas = [];
  
      res['resultado'].forEach(organograma => {
        const organogramaRaiz = {
          ativo: organograma.ativo,
          checked: organograma.checked,
          id: organograma.sequencia,
          dataCriacao: organograma.dataCriacao,
          dscOrganograma: organograma.dscOrganograma,
          niveis: [],
          expanded: false,
          isPai: true
        };
  
        organograma.niveis.forEach(nivel => {
          const nivelOrganograma = {
            id: nivel.sequenciaNivel,
            dscOrganograma: nivel.dscOrganogramaNivel,
            parentId: organograma.sequencia,
            expanded: false,
            isPai: false, 
            checked: nivel.checked,
            items: []
          };
  
          if (nivel.subnivel && nivel.subnivel.length > 0) {
            nivel.subnivel.forEach(subnivel => {
              const subnivelOrganograma = {
                id: subnivel.sequenciaNivel,
                dscOrganograma: subnivel.dscOrganogramaNivel,
                parentId: nivel.sequenciaNivel,
                expanded: false,
                isPai: false, 
                checked: subnivel.checked,
                items: []
              };
              nivelOrganograma.items.push(subnivelOrganograma);
            });
          }
  
          organogramaRaiz.niveis.push(nivelOrganograma);
        });
  
        organogramas.push(organogramaRaiz);
      });
  
      this.dataOrg = organogramas;
  
      setTimeout(() => {
        this.loading = false;
        this.cd.detectChanges();
      }, 1000);
    });
  }
  
  onOrganogramaChange(organograma: any) {
    organograma.checked = !organograma.checked;
    this.updateCheckedItems();
  }
  
  onNivelChange(nivel: any) {
    nivel.checked = !nivel.checked;
    this.updateCheckedItems();
  }
  
  onSubnivelChange(subnivel: any) {
    subnivel.checked = !subnivel.checked;
    this.updateCheckedItems();
  }
  
  updateCheckedItems() {
    this.checkedItem = [];
    this.dataOrg.forEach(organograma => {
      if (organograma.checked) {
        this.checkedItem.push(organograma);
      }
      organograma.niveis.forEach(nivel => {
        if (nivel.checked) {
          this.checkedItem.push(nivel);
        }
        nivel.items.forEach(subnivel => {
          if (subnivel.checked) {
            this.checkedItem.push(subnivel);
          }
        });
      });
    });
  }
  
  setLabels() {
    this.labels = {
      titulo: this.translate.instant('CONFIGURACOES.USUARIOS.TITULOEDITAR'),
      tipo_usuario: this.translate.instant('GLOBAIS.TIPOUSUARIO'),
      pf: this.translate.instant('GLOBAIS.PESSOAFISICA'),
      pj: this.translate.instant('GLOBAIS.PESSOAJURIDICA'),
      situacao: this.translate.instant('GLOBAIS.SITUACAO'),
      ativo: this.translate.instant('GLOBAIS.ATIVO'),
      inativo: this.translate.instant('GLOBAIS.INATIVO'),
      bloqueado: this.translate.instant('GLOBAIS.BLOQUEADO'),
      aberturaCadastro: this.translate.instant('GLOBAIS.ABERTURACADASTRO'),
      cpfcnpj: this.translate.instant('GLOBAIS.CPFCNPJ'),
      nomeRazaoSocial: this.translate.instant('GLOBAIS.NOMERAZAOSOCIAL'),
      inscricaoMunicipal: this.translate.instant('GLOBAIS.INSCRICAOMUNICIPAL'),
      celular:
        this.translate.instant('GLOBAIS.TELEFONE') +
        ' ' +
        this.translate.instant('GLOBAIS.CELULAR'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      telefone:
        this.translate.instant('GLOBAIS.TELEFONE'),
      emailCom:
        this.translate.instant('GLOBAIS.EMAIL') +
        ' ' +
        this.translate.instant('GLOBAIS.COMERCIAL'),
      cep: this.translate.instant('GLOBAIS.CEP'),
      logradouro: this.translate.instant('GLOBAIS.LOGRADOURO'),
      numero: this.translate.instant('GLOBAIS.NUMERO'),
      complemento: this.translate.instant('GLOBAIS.COMPLEMENTO'),
      semvalidacao: this.translate.instant('GLOBAIS.SEMVALIDACAO'),
      pasta: this.translate.instant('CONFIGURACOES.USUARIOS.PASTAALFRESCO'),
      criouSenha: this.translate.instant('CONFIGURACOES.USUARIOS.CRIOUSENHA'),

      situacaoUsuario: this.translate.instant('CONFIGURACOES.USUARIOS.SITUACAOUSUARIO'),

      ativacaoPorOficio: this.translate.instant('CONFIGURACOES.USUARIOS.ATIVARPOROFICIO'),
      sim: this.translate.instant('GLOBAIS.SIM'),
      nao: this.translate.instant('GLOBAIS.NAO'),
    };
  }

  alertaDadosRepresentante() {
    Swal.fire({
      html: 'Contribuinte Jurídico possui, obrigatoriamente, Representante Legal.',
      confirmButtonText: 'Fechar'
    })
  }
}
